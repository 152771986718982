







































































































































import { computed, defineComponent, Ref, ref } from '@vue/composition-api';
import { useUser, User } from '@/admin/user';
import { useNotification } from '@/composition/notification';
import { usePayment } from '@/admin/payment';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'TestUsers',
  components: {
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('testusers'));

    const { getUserGroups, createTestUser, getTestUsers, updateTestUsers, updateUserGroups } = useUser();
    const notification = useNotification();
    const { getSubscriptionPlans } = usePayment();

    const step = ref(1);
    const email = ref('');
    const password = ref('');
    const isSending = ref(false);

    // テストユーザー一覧
    const headers = [
      { text: 'ユーザーID', value: 'userId' },
      { text: 'メールアドレス', value: 'email' },
      { text: '所属プラン', value: 'subscriptionNames' },
      { text: '登録日', value: 'createDate' },
      { text: '退会日', value: 'deleteDate' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];
    const testUsers: Ref<User[]> = ref([]);
    const isLoading = ref(true);
    const isSaving = ref(false); // 更新中フラグ
    const isActive = ref(false); // モーダル表示フラグ
    const isCheckedAll = ref(false); // 全て選択フラグ

    const groupNames: Ref<{ value: string; text: string }[]> = ref([]);
    const selectedGroupNames: Ref<string[]> = ref([]);

    const modalUserId = ref(''); // モーダル用ユーザーID
    const modalEmail = ref(''); // モーダル用メールアドレス
    const modalGroupNames: Ref<string[]> = ref([]); // モーダル用所属プラン

    // テーブル高さ
    const tableHeight = window.innerHeight - 64 - 40;

    /** 全て選択のON/OFFを切り替える処理 */
    const toggleCheckAll = () => {
      selectedGroupNames.value = isCheckedAll.value ? groupNames.value.map(groupName => groupName.value) : [];
    };

    Promise.all([
      getUserGroups(),
      getTestUsers().then(
        (result) => result,
        () => {
          notification.error('テストユーザー一覧は許可されたIPアドレスでのみ閲覧可能です');
          return null;
        }
      ),
      getSubscriptionPlans(),
    ]).then(([_userGroups, _testUsers, _plans]) => {
      groupNames.value = _userGroups
        .filter((userGroup) => userGroup.groupName !== 'testUser')
        .map((userGroup) => ({
          text:
            _plans.find((plan) => userGroup.groupName.replace('plan:', '') === plan.subscriptionPlanId)
              ?.subscriptionPlanName || userGroup.groupName,
          value: userGroup.groupName,
        }));
      if (_testUsers) testUsers.value = _testUsers;
      isLoading.value = false;
    });

    const send = async () => {
      if (!email.value) {
        notification.error('メールアドレスを入力してください');
        return;
      }

      isSending.value = true;
      try {
        const result = await createTestUser(email.value, [...selectedGroupNames.value, 'testUser']);
        step.value = 2;
        password.value = result.password;
      } catch (error) {
        notification.error(error);
      }
      isSending.value = false;
    };

    /**
     * モーダル表示処理
     *
     * @param item - 選択したユーザー情報
     * @param index - 選択したユーザーのインデックス
     */
    const openEditModal = (item: User, index: number) => {
      modalUserId.value = item.userId;
      modalEmail.value = item.email;
      // モーダル用所属プランはモーダル表示時・更新時のみしか使わない、かつ、閉じる時にリセットするにはモーダル外をクリックした時の考慮も必要になり、watchを使うにはバグを引き起こしやすいのでモーダル表示時にリセットする
      modalGroupNames.value = [];
      // 画面に表示する用の所属プラン名だと被る可能性があるため、一意となるプランのIDで絞り込みを行う
      const groupNamesObject = groupNames.value
        .filter(
          (groupName) =>
            item.subscriptionNames.includes(groupName.text) &&
            testUsers.value.findIndex((testUser) => testUser.userId === item.userId) === index &&
            testUsers.value[index].subscriptionPlanIds.includes(groupName.value.replace('plan:', ''))
        )
        .map((groupName) => groupName.value);
      modalGroupNames.value.push(...groupNamesObject);
      isActive.value = true;
    };

    /**
     * モーダル表示閉じる処理
     */
    const cancel = () => {
      isActive.value = false;
    };

    /**
     * 所属プラン(ユーザーグループ)更新処理
     */
    const save = async () => {
      isActive.value = false;
      isSaving.value = true;
      try {
        await updateUserGroups(modalUserId.value, modalGroupNames.value);
        // 再取得処理が複雑なので、ユーザーの更新部分だけ置き換えにする
        const testUsersObject = testUsers.value.map((user) => {
          if (user.userId === modalUserId.value) {
            // APIで取得し直さないので、プランを選択肢順に並び替える
            const sortGroupNames = modalGroupNames.value.sort((a, b) => {
              const aIndex = groupNames.value.findIndex((group) => group.value === a);
              const bIndex = groupNames.value.findIndex((group) => group.value === b);
              return aIndex - bIndex;
            });
            return {
              ...user,
              subscriptionNames: sortGroupNames.map((groupName) => {
                const plan = groupNames.value.find((group) => group.value === groupName);
                return plan ? plan.text : groupName;
              }),
              subscriptionPlanIds: sortGroupNames.map((planId) => {
                const plan = groupNames.value.find((group) => group.value === planId);
                return plan ? plan.value.replace('plan:', '') : planId.replace('plan:', '');
              }),
            };
          }
          return user;
        });
        testUsers.value = [];
        testUsers.value.push(...testUsersObject);
        // 取得したユーザー情報を保持している場合にAPIを叩かないので、保持しているデータにも更新をかける
        updateTestUsers(testUsers.value);
        notification.notify('変更しました。');
      } catch (error) {
        notification.error(error);
      } finally {
        isSaving.value = false;
      }
    };

    return {
      pageTitle: 'テストユーザー登録',
      myRoleSettings,
      step,
      email,
      password,
      isSending,
      groupNames,
      selectedGroupNames,
      toggleCheckAll,
      send,
      headers,
      testUsers,
      isLoading,
      isSaving,
      isActive,
      isCheckedAll,
      modalUserId,
      modalEmail,
      modalGroupNames,
      tableHeight,
      openEditModal,
      cancel,
      save,
    };
  },
});
